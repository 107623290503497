import React from 'react'

const Loading = () => {
  return (
    <>
    </>
    // <div className="loader-container"></div>
  )
}

export default Loading
